import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as budgetingH6ZkukzRWbMeta } from "/app/pages/budgeting.vue?macro=true";
import { default as businessl9xJeDqVoeMeta } from "/app/pages/business.vue?macro=true";
import { default as cardsPWuoOV9bp7Meta } from "/app/pages/cards.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as _91id_93xRGFwWfgMhMeta } from "/app/pages/coins/[id].vue?macro=true";
import { default as indexta6b91hQU9Meta } from "/app/pages/coins/index.vue?macro=true";
import { default as depositsCddPR7XUIVMeta } from "/app/pages/deposits.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as apinUOvaXNqlUMeta } from "/app/pages/locations/api.js?macro=true";
import { default as filtersAhuCf7ppvOMeta } from "/app/pages/locations/components/filters.vue?macro=true";
import { default as mapXCapa0VLgkMeta } from "/app/pages/locations/components/map.vue?macro=true";
import { default as old_45logic4V201sciHUMeta } from "/app/pages/locations/components/old-logic.js?macro=true";
import { default as search_45fieldPn3RaLpfCJMeta } from "/app/pages/locations/components/search-field.vue?macro=true";
import { default as indexILfcf96YXkMeta } from "/app/pages/locations/index.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as officesXrLYfoigSgMeta } from "/app/pages/offices.vue?macro=true";
import { default as online24Ca2pxBgCm5Meta } from "/app/pages/online24.vue?macro=true";
import { default as _91id_93VVAEZJEHZ4Meta } from "/app/pages/payments/[id].vue?macro=true";
import { default as errorpgmqcJam7fMeta } from "/app/pages/payments/error.vue?macro=true";
import { default as index4DIviTw3fSMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as successZawJoPYqJUMeta } from "/app/pages/payments/success.vue?macro=true";
import { default as safeboxesr6ECYcghNCMeta } from "/app/pages/safeboxes.vue?macro=true";
import { default as vacanciesAfH1oWmZlHMeta } from "/app/pages/vacancies.vue?macro=true";
export default [
  {
    name: "about___ru___default",
    path: "/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ab",
    path: "/ab/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___tr",
    path: "/tr/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ar",
    path: "/ar/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___ru___default",
    path: "/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___ru",
    path: "/ru/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___en",
    path: "/en/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___es",
    path: "/es/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___ab",
    path: "/ab/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___tr",
    path: "/tr/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "budgeting___ar",
    path: "/ar/budgeting",
    component: () => import("/app/pages/budgeting.vue").then(m => m.default || m)
  },
  {
    name: "business___ru___default",
    path: "/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___ru",
    path: "/ru/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___en",
    path: "/en/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___es",
    path: "/es/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___ab",
    path: "/ab/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___tr",
    path: "/tr/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "business___ar",
    path: "/ar/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "cards___ru___default",
    path: "/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___ru",
    path: "/ru/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___en",
    path: "/en/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___es",
    path: "/es/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___ab",
    path: "/ab/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___tr",
    path: "/tr/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cards___ar",
    path: "/ar/cards",
    component: () => import("/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: "cart___ru___default",
    path: "/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___ru",
    path: "/ru/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___es",
    path: "/es/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___ab",
    path: "/ab/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___tr",
    path: "/tr/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___ar",
    path: "/ar/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "coins-id___ru___default",
    path: "/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___ru",
    path: "/ru/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___en",
    path: "/en/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___es",
    path: "/es/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___ab",
    path: "/ab/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___tr",
    path: "/tr/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins-id___ar",
    path: "/ar/coins/:id()",
    component: () => import("/app/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins___ru___default",
    path: "/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___ru",
    path: "/ru/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___en",
    path: "/en/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___es",
    path: "/es/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___ab",
    path: "/ab/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___tr",
    path: "/tr/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "coins___ar",
    path: "/ar/coins",
    component: () => import("/app/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "deposits___ru___default",
    path: "/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___ru",
    path: "/ru/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___en",
    path: "/en/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___es",
    path: "/es/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___ab",
    path: "/ab/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___tr",
    path: "/tr/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "deposits___ar",
    path: "/ar/deposits",
    component: () => import("/app/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: "index___ru___default",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ab",
    path: "/ab",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations-api___ru___default",
    path: "/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___ru",
    path: "/ru/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___en",
    path: "/en/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___es",
    path: "/es/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___ab",
    path: "/ab/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___tr",
    path: "/tr/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-api___ar",
    path: "/ar/locations/api",
    component: () => import("/app/pages/locations/api.js").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___ru___default",
    path: "/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___ru",
    path: "/ru/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___en",
    path: "/en/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___es",
    path: "/es/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___ab",
    path: "/ab/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___tr",
    path: "/tr/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-filters___ar",
    path: "/ar/locations/components/filters",
    component: () => import("/app/pages/locations/components/filters.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___ru___default",
    path: "/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___ru",
    path: "/ru/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___en",
    path: "/en/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___es",
    path: "/es/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___ab",
    path: "/ab/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___tr",
    path: "/tr/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-map___ar",
    path: "/ar/locations/components/map",
    component: () => import("/app/pages/locations/components/map.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___ru___default",
    path: "/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___ru",
    path: "/ru/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___en",
    path: "/en/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___es",
    path: "/es/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___ab",
    path: "/ab/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___tr",
    path: "/tr/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-old-logic___ar",
    path: "/ar/locations/components/old-logic",
    component: () => import("/app/pages/locations/components/old-logic.js").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___ru___default",
    path: "/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___ru",
    path: "/ru/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___en",
    path: "/en/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___es",
    path: "/es/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___ab",
    path: "/ab/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___tr",
    path: "/tr/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations-components-search-field___ar",
    path: "/ar/locations/components/search-field",
    component: () => import("/app/pages/locations/components/search-field.vue").then(m => m.default || m)
  },
  {
    name: "locations___ru___default",
    path: "/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___ru",
    path: "/ru/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___en",
    path: "/en/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___es",
    path: "/es/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___ab",
    path: "/ab/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___tr",
    path: "/tr/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___ar",
    path: "/ar/locations",
    component: () => import("/app/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id___ru___default",
    path: "/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___ru",
    path: "/ru/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___es",
    path: "/es/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___ab",
    path: "/ab/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___tr",
    path: "/tr/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news-id___ar",
    path: "/ar/news/:id()",
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news___ru___default",
    path: "/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___ru",
    path: "/ru/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___es",
    path: "/es/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___ab",
    path: "/ab/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___tr",
    path: "/tr/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___ar",
    path: "/ar/news",
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "offices___ru___default",
    path: "/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___ru",
    path: "/ru/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___en",
    path: "/en/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___es",
    path: "/es/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___ab",
    path: "/ab/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___tr",
    path: "/tr/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "offices___ar",
    path: "/ar/offices",
    component: () => import("/app/pages/offices.vue").then(m => m.default || m)
  },
  {
    name: "online24___ru___default",
    path: "/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___ru",
    path: "/ru/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___en",
    path: "/en/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___es",
    path: "/es/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___ab",
    path: "/ab/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___tr",
    path: "/tr/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "online24___ar",
    path: "/ar/online24",
    component: () => import("/app/pages/online24.vue").then(m => m.default || m)
  },
  {
    name: "payments-id___ru___default",
    path: "/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___ru",
    path: "/ru/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___en",
    path: "/en/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___es",
    path: "/es/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___ab",
    path: "/ab/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___tr",
    path: "/tr/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-id___ar",
    path: "/ar/payments/:id()",
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-error___ru___default",
    path: "/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___ru",
    path: "/ru/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___en",
    path: "/en/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___es",
    path: "/es/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___ab",
    path: "/ab/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___tr",
    path: "/tr/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-error___ar",
    path: "/ar/payments/error",
    component: () => import("/app/pages/payments/error.vue").then(m => m.default || m)
  },
  {
    name: "payments___ru___default",
    path: "/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___ru",
    path: "/ru/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___en",
    path: "/en/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___es",
    path: "/es/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___ab",
    path: "/ab/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___tr",
    path: "/tr/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___ar",
    path: "/ar/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___ru___default",
    path: "/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___ru",
    path: "/ru/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___en",
    path: "/en/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___es",
    path: "/es/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___ab",
    path: "/ab/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___tr",
    path: "/tr/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-success___ar",
    path: "/ar/payments/success",
    component: () => import("/app/pages/payments/success.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___ru___default",
    path: "/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___ru",
    path: "/ru/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___en",
    path: "/en/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___es",
    path: "/es/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___ab",
    path: "/ab/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___tr",
    path: "/tr/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "safeboxes___ar",
    path: "/ar/safeboxes",
    component: () => import("/app/pages/safeboxes.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___ru___default",
    path: "/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___ru",
    path: "/ru/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___en",
    path: "/en/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___es",
    path: "/es/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___ab",
    path: "/ab/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___tr",
    path: "/tr/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___ar",
    path: "/ar/vacancies",
    component: () => import("/app/pages/vacancies.vue").then(m => m.default || m)
  }
]