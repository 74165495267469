export const LOCALSTORAGE_KEYS = {
  cart: 'amra-bank-cart',
  order: 'amra-bank-order',
};

export const COOKIE_KEYS = {
    CHAT_CLIENT_ID: 'chat.client.id',
    CHAT_TICKET_ID: 'chat.ticket.id',
  };
  
  export const monthNamesShort = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];
  
  export const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  
  export const weekDayNames = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];
  
  export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY HH:mm";
  export const DEFAULT_HOURS_FORMAT = "HH:mm";
  