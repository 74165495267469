export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"index, follow"},{"name":"keywords","content":"абхазия,сухум,гагра,банк,амрабанк,амра банк,банки абхазии,банк абхазии,банкомат,терминал,кредит,вклад,переводы,коллекционные монеты"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"hid":"blinkloader","innerHTML":"\n            (function(src, cb) {\n              var s = document.createElement('script'); s.setAttribute('src', src);\n              s.onload = cb; (document.head || document.body).appendChild(s);\n            })('https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js', function() {\n              window.Blinkloader.optimize({\n                pubkey:'011e24b0e8e857ca7e0c',\n                fadeIn:true,\n                lazyload:true,\n                smartCompression:true,\n                responsive:true,\n                retina:true,\n                webp:true\n              });\n            })\n          ","type":"text/javascript"}],"noscript":[],"title":"Амрабанк"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false