import {
  defineNuxtPlugin,
  addRouteMiddleware,
  useCookie,
  navigateTo,
} from "#app";

export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    "global-redirect",
    async (to) => {
      if (to.path === "/") return await navigateTo(`/ru`);
      return true;
    },
    { global: true }
  );
});
