<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup>
import { useCartStore } from "~/stores";

const cartStore = useCartStore();

cartStore.$subscribe(() => {
  localStorage.setItem(LOCALSTORAGE_KEYS.cart, JSON.stringify(cartStore.cart));
});

onMounted(() => {
  let savedCart = localStorage.getItem(LOCALSTORAGE_KEYS.cart) || "{}";
  cartStore.setCart(JSON.parse(savedCart));
});
</script>
